import { 
    ADD_EMPLOYEE_FEEDBACK_SUCCESS,
    ADD_EMPLOYEE_FEEDBACK_FAIL
} from "../Constants/const";   
import {getApi,postApi,getApiWithHeader} from '../../ApiCallFunction';

const addEmployeeFeedbackSuccess = (data) => {
    return {
        type: ADD_EMPLOYEE_FEEDBACK_SUCCESS,
        payload: data,
        };
    };
  
const addEmployeeFeedbackFail = (data) => {
    return {
        type: ADD_EMPLOYEE_FEEDBACK_FAIL,
        payload: data,
        };
    };

export const addEmployeeFeedback = (data) => {
    return async (dispatch, getState) => {
        const { error, result } = await postApi(`addfeedback`, {
        ...data,
        });
        if (!error) {
        return dispatch(addEmployeeFeedbackSuccess(result));
        }
        return dispatch(addEmployeeFeedbackFail(result));
    };
};
