import React from 'react'
import { Routes, Route  } from "react-router-dom";
import FeedbackForm from "./components/employee/feedbackForm";
import AdminLoginForm from "./components/admin/adminLoginForm";
import FeedbackList from "./components/admin/feedbackList";
import 'antd/dist/antd.css';
import  Protected  from "./Auth";


export default function Router() {
    return (
        <Routes>
            <Route path="/" element={<FeedbackForm/>}/>
            <Route path="/admin" element={<AdminLoginForm/>}/>
            <Route path="/feedbacks" element={<Protected><FeedbackList/></Protected>}/>
        </Routes>
    )
}
