import React, { useEffect, useState } from 'react';
import { Select, Form, Input, notification, Button, useForm } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { getEmployee } from "../../redux/actions/getEmployee.action";
import { addEmployeeFeedback } from "../../redux/actions/addFeedback.action";

export default function FeedbackForm() {

    const [form] = Form.useForm();

    const { Option } = Select;
    const { TextArea } = Input;
    const dispatch = useDispatch();
    const emp_list = useSelector((state) => state.getemployee.data.result || []);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        dispatch(getEmployee());
    }, []);

    const onFinish = (values) => {
        setLoader(true);
        dispatch(addEmployeeFeedback(values)).then((value) => {
            if (value.payload.success) {
                setLoader(false);
                notification.success({
                    message: value.payload.message,
                });
                form.resetFields();
            } else {
                setLoader(false);
                notification.error({
                    message: value.payload.message,
                });
            }
        })
    };

    return (
        <>
            <div className="container-contact100">
                <div className="wrap-contact100">
                    <Form name="basic" form={form} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}
                        initialValues={{ remember: true }} autoComplete="off" onFinish={onFinish} >
                        <Form.Item label="Select Employee" name="to" rules={[{ required: true, message: "Please Select Employee!" }]}>
                            <Select showSearch placeholder="Select a person" optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                <Option key="general" >General opinion</Option>
                                {emp_list.map(item => {
                                    return <Option key={item.name} >{(item.name).charAt(0).toUpperCase() + (item.name).slice(1)}</Option>
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Your Feedback" name="feedback" rules={[{ required: true, message: "Please Enter a Feedback!" }]}>
                            <TextArea rows={4} />
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 10, span: 14 }}>
                            <Button loading={loader} type="primary" htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    )
}
