import { 
    ADMIN_LOGIN_SUCCESS,
    ADMIN_LOGIN_FAILURE,
} from "../Constants/const";   
import {getApi,getApiWithHeader,postApi} from '../../ApiCallFunction';
;

const adminLoginSuccess = (data) => {
    return {
        type: ADMIN_LOGIN_SUCCESS,
        payload: data,
        };
    };
  
const adminLoginFail = (data) => {
    return {
        type: ADMIN_LOGIN_FAILURE,
        payload: data,
        };
    };

export const adminLogin = (data) => {
    return async (dispatch, getState) => {
        const { error, result } = await postApi(`adminLogin`, {
        ...data,
        });
        if (!error) {
        return dispatch(adminLoginSuccess(result));
        }
        return dispatch(adminLoginFail(result));
    };
};
