import { 
    GET_EMPLOYEE_FEEDBACK_SUCCESS,
    GET_EMPLOYEE_FEEDBACK_FAIL 
} from "../Constants/const";   
import {getApi,postApi,getApiWithHeader} from '../../ApiCallFunction';
;


const getEmployeeFeedbackSuccess = (data) => {
    return {
        type: GET_EMPLOYEE_FEEDBACK_SUCCESS,
        payload: data,
        };
    };

const getEmployeeFeedbackFail = (data) => {
    return {
        type: GET_EMPLOYEE_FEEDBACK_FAIL,
        payload: data,
        };
    };

export const getEmployeeFeedback = () => {
    return async (dispatch, getState) => {
        const { error, result } = await getApiWithHeader(`getfeedback`);
        if (!error) {
        return dispatch(getEmployeeFeedbackSuccess(result));
        }
        return dispatch(getEmployeeFeedbackFail(result));
    };
};
