import { combineReducers } from "redux";
import getFeedbackReducer from './getFeedback.reducer';
import addFeedbackReducer from "./addFeedback.reducer";
import getEmployeeReducer from "./getEmployee.reducer";
import adminLoginReducer from "./adminLogin.reducer";
import protectedReducer from "./protected.reducer";

const rootReducers = combineReducers({
    getfeedback: getFeedbackReducer,
    addfeedback: addFeedbackReducer,
    getemployee: getEmployeeReducer,
    protected: protectedReducer,
    adminauth: adminLoginReducer,
});

export default rootReducers;