import axios from "axios";
import Cookies from 'js-cookie'

const setUrl = process.env.REACT_APP_BASE_URL
const API_SUCCESS =200

var headers={}
// headers["id"] = Cookies.get("id") || ""
// headers["username"] = Cookies.get("username") || ""
headers["x-access-token"] = Cookies.get("x-access-token");

const getToken = () => {
  return {
    headers: {
      'x-access-token': Cookies.get("x-access-token")
    }    
  };
};

export const getApi = async (url) => {
  try {
    const result = await axios.get(`${setUrl}${url}`);
    if (result.status === API_SUCCESS) {
      return { error: false, result: result.data };
    }
    return { error: true, result: result.data };
  } catch (error) {
    return { error: true, result: [] };
  }
};

export const getApiWithHeader = async (url) => {
  try {
    const result = await axios.get(`${setUrl}${url}`, getToken());
    if (result.status === API_SUCCESS) {
      return { error: false, result: result.data };
    }
    return { error: true, result: result.data };
  } catch (error) {
    return { error: true, result: [] };
  }
};

export const postApi = async (url, data) => {
  try {
    const result = await axios.post(`${setUrl}${url}`, data);
    if (result.status === API_SUCCESS) {
      return { error: false, result: result.data };
    }else{
      return { error: true, result: result.data };
    }
  } catch (error) {
    return { error: true, result: [] };
  }
};