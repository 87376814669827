import React, { useState } from 'react';
import { Select, Form, Input, Button, notification } from 'antd';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from "react-redux";
import { adminLogin } from "../../redux/actions/adminLogin.action";
import { useNavigate } from 'react-router-dom';

export default function FeedbackForm() {

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [loader, setLoader] = useState(false);

    const submitForm = (values) => {
        setLoader(true);
        dispatch(adminLogin(values)).then((record) => {
            if (record.payload.success == true) {
                setLoader(false);
                // Cookies.set('username', record.payload.result.username, { path: '/' });
                // Cookies.set('id', record.payload.result.username, { path: '/' });
                Cookies.set('x-access-token', record.payload.result.accessToken, { path: '/' });
                navigate(`/feedbacks`);
            } else {
                form.resetFields();
                setLoader(false);
                notification.error({
                    message: "Login Failed",
                    // message: record.payload.message,
                });
            }
        });
    };


    return (
        <div className="container-contact100">
            <div className="wrap-contact100">
                <Form name="basic" form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={submitForm} initialValues={{ remember: true }} autoComplete="off">
                    <Form.Item label="Username" name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                        <Input.Password />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button loading={loader} type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
