import React , { useState ,useEffect} from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkAuth } from "./redux/actions/protected.action";
import Cookies from 'js-cookie'

const Protected = ({ children }) => {

  // const [auth, setAuth] = useState(true)
  
  // const authReducerData = useSelector( state => state.protected )
  // const dispatch = useDispatch();
  
  // useEffect(() => {
  //       dispatch(checkAuth());
  //   },[]);
    
  //   useEffect(() => {
  //       const data=authReducerData;
  //       // console.log(`data`, data);
  //       if (!data.error){
  //           setAuth(true)
  //         }
  //       },[authReducerData]);
        
        
    const isAuthenticated = Cookies.get("x-access-token") ? true : false;
    return isAuthenticated ? children : <Navigate to="/admin" />;
};

export default Protected;
