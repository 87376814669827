import { 
    GET_EMPLOYEE_SUCCESS,
    GET_EMPLOYEE_FAILURE,
} from "../Constants/const";   
import {getApi,postApi} from '../../ApiCallFunction';


const getEmployeeSuccess = (data) => {
    return {
        type: GET_EMPLOYEE_SUCCESS,
        payload: data,
        };
    };

const getEmployeeFail = (data) => {
    return {
        type: GET_EMPLOYEE_FAILURE,
        payload: data,
        };
    };

export const getEmployee = () => {
    return async (dispatch, getState) => {
        const { error, result } = await getApi(`getemployee`);
        if (!error) {
        return dispatch(getEmployeeSuccess(result));
        }
        return dispatch(getEmployeeFail(result));
    };
};
