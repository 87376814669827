import { 
    ADMIN_LOGIN_SUCCESS,
    ADMIN_LOGIN_FAILURE
} from "../Constants/const";

const initStat = { data: [], error: false, errorData: "", isLoading: true };

export default (state = initStat, action) => {
    switch (action.type) {
      case ADMIN_LOGIN_SUCCESS:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
        };
      case ADMIN_LOGIN_FAILURE:
        return {
          ...state,
          isLoading: false,
          errorData: action.payload,
          error: true,
        };
      default:
        return { ...state };
    }
  };