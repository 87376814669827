import { 
    GET_EMPLOYEE_SUCCESS,
    GET_EMPLOYEE_FAILURE,
} from "../Constants/const";

const initStat = { data: [], error: false, errorData: "", isLoading: true };

export default (state = initStat, action) => {
    switch (action.type) {
      case GET_EMPLOYEE_SUCCESS:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
        };
      case GET_EMPLOYEE_FAILURE:
        return {
          ...state,
          isLoading: false,
          errorData: action.payload,
          error: true,
        };
      default:
        return { ...state };
    }
  };