
// ============================= Employee Const =============================
export const ADD_EMPLOYEE_SUCCESS = "ADD_EMPLOYEE_SUCCESS";
export const ADD_EMPLOYEE_FAIL = "ADD_EMPLOYEE_FAIL";
export const GET_EMPLOYEE_SUCCESS = "get_employee_success";
export const GET_EMPLOYEE_FAILURE = "get_employee_failure";

// ============================= Feedback Const =============================
export const ADD_EMPLOYEE_FEEDBACK_SUCCESS = "ADD_EMPLOYEE_FEEDBACK_SUCCESS";
export const ADD_EMPLOYEE_FEEDBACK_FAIL = "ADD_EMPLOYEE_FEEDBACK_FAIL";
export const GET_EMPLOYEE_FEEDBACK_SUCCESS = "GET_EMPLOYEE_FEEDBACK_SUCCESS";
export const GET_EMPLOYEE_FEEDBACK_FAIL = "GET_EMPLOYEE_FEEDBACK_FAIL";


// ============================= Admin Const =============================

export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS"
export const ADMIN_LOGIN_FAILURE = "ADMIN_LOGIN_FAILURE"

export const PROTECTED_AUTH_SUCCESS = "PROTECTED_AUTH_SUCCESS"
export const PROTECTED_AUTH_FAILURE = "PROTECTED_AUTH_FAILURE"