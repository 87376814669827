import { 
    PROTECTED_AUTH_SUCCESS,
    PROTECTED_AUTH_FAILURE
} from "../Constants/const";   
import {getApi,getApiWithHeader,postApi} from '../../ApiCallFunction';

const protectedAuthSuccess = (data) => {
    return {
        type: PROTECTED_AUTH_SUCCESS,
        payload: data,
        };
    };
    
const protectedAuthFail = (data) => {
    return {
        type: PROTECTED_AUTH_FAILURE,
        payload: data,
        };
    };

export const checkAuth = () => {
    return async (dispatch, getState) => {
        const { error, result } = await getApiWithHeader(`checkauth`);
        if (!error) {
        return dispatch(protectedAuthSuccess(result));
        }
        return dispatch(protectedAuthFail(result));
    };
};
