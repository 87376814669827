import { BrowserRouter } from "react-router-dom";
import  Router from "./router";
import "./assets/css/main.css";

function App() {
	return (
		<BrowserRouter>
			<div className="bg-contact100">
				{/* <div className="container-contact100"> */}
				{/* <div className="wrap-contact100"> */}
				<Router/>
				{/* </div> */}
				{/* </div> */}
			</div>
			<div className="bg-contact20">
				<h1 className="wrap-contact20">
					<a style={{color: "#f0572b"}} href="https://itechnotion.com/" target="_blank">iTechNotion Pvt. Ltd.</a> © {new Date().getFullYear()} Created by Jay Patel and Hussain Sakariya
					
				</h1>
			</div>
		</BrowserRouter>
	);
}

export default App;
