import { React, useEffect, useState } from 'react';
import { Button, Tooltip, Input, DatePicker } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { PoweroffOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom';
import { getEmployeeFeedback } from "../../redux/actions/getFeedback.action";
import { SearchOutlined } from "@ant-design/icons";
import  moment from 'moment';
import "antd/dist/antd.css";
import Table from "ant-responsive-table";


const { RangePicker } = DatePicker;

export default function FeedbackForm() {


  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const feedback_list = useSelector((state) => state.getfeedback.data.result || []);


  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);

  function logoutuser() {
    setLoader(true);
    Cookies.remove('x-access-token', { path: '' }) // removed!
    setLoader(false);
    navigate(`/admin`);
  };

  useEffect(() => {
    setLoading(true)
    dispatch(getEmployeeFeedback()).finally(() => setLoading(false));
  }, []);

  useEffect(() => {

    if(feedback_list.length > 0) {
      
        const updatedData =  feedback_list.map((data, index) => {
          return {
            key: index + 1,
            no: index + 1,
            name: data.to,
            feedback: data.feedback,
            date: moment(data.created_date, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY"),
            unixTime: moment(data.created_date, "DD-MM-YYYY hh:mm:ss").unix()
          }
        })

        setFilteredData(updatedData)
        setData(updatedData)

    }
  }, [feedback_list]);

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      showOnResponse: true,
      showOnDesktop: true,
      ellipsis: {
        showTitle: false,
      },
      width: '5%',
      sorter: (a, b) => a.feedback.length - b.feedback.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      showOnResponse: true,
      showOnDesktop: true,
      // render: text => <a>{text}</a>,
      width: '10%',
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend', 'ascend'],
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
                  onClick={() => {
                    confirm();
                  }}
                  type="primary"
                >
                  Search
                </Button> */}
            {/* <Button
                  onClick={() => {
                    clearFilters();
                  }}
                  type="danger"
                >
                  Reset
                </Button> */}
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.name.toLowerCase().includes(value.toLowerCase());
      },

    },
    {
      title: 'Submitted On',
      dataIndex: 'date',
      key: 'date',
      showOnResponse: true,
      showOnDesktop: true,
      ellipsis: {
        showTitle: false,
      },
      width: '20%',
    },
    {
      title: 'Feedback',
      dataIndex: 'feedback',
      key: 'feedback',
      showOnResponse: true,
      showOnDesktop: true,
      // ellipsis: {
      //   showTitle: false,
      // },
      sorter: (a, b) => a.feedback.length - b.feedback.length,
      sortDirections: ['descend', 'ascend'],
      render: feedback => (
        <Tooltip placement="topLeft" title={feedback}>
          {feedback}
        </Tooltip>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      showOnResponse: false,
      showOnDesktop: false,
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      sortDirections: ['descend', 'ascend'],
      // defaultSortOrder: 'descend',
      // sorter: (a, b) => new Date(a.date) - new Date(b.date),
      render: date => (
        <Tooltip placement="topLeft" title={date}>
          {date}
        </Tooltip>
      ),
    },
  ];


  const onDateChange = (date, dateString) => {
    const startDate = moment(dateString[0], "DD-MM-YYYY").unix();
    const endDate = moment(dateString[1], "DD-MM-YYYY").unix();

    if(!Number(startDate) || !Number(endDate)) {
      return setData(filteredData)
    }

    setData(() => {
      return filteredData.filter(item => {
        const itemDate = moment(item.date, "DD-MM-YYYY HH:mm:ss").unix();
        if(itemDate >= startDate && itemDate <= endDate) {
          return item
        }
        return
      });
    });
  };


  return (
    <>
      <div className="container-contact101">
        <div className="wrap-contact101">
          <div style={{ position: 'relative', marginBottom: "50px", width: "100%" }}>
            <Button style={{ position: 'absolute', right: "0px" }} type="ghost" danger shape="circle" icon={<PoweroffOutlined />} loading={loader} onClick={logoutuser} />
          </div>
          <div style={{ display: "flex", alignItems: "end", justifyContent: "end" , marginBottom: 10, width: "100%" }} >
          <RangePicker format={"DD-MM-YYYY"} onChange={onDateChange} />
          </div>
          <div>
            {/* <Table style={{ display: "flex", flex: 1, margin: 10 }} columns={columns} dataSource={data} /> */}
            <Table
              antTableProps={{
                loading:loading,
                showHeader: true,
                columns,
                dataSource: data,
                pagination: true
              }}
              mobileBreakPoint={768}
            />
          </div>
        </div>
      </div>
    </>
  )
}
