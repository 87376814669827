import { 
    PROTECTED_AUTH_SUCCESS,
    PROTECTED_AUTH_FAILURE
} from "../Constants/const";

const initStat = { data: [], error: false, errorData: "", isLoading: true };

export default (state = initStat, action) => {
  switch (action.type) {
    case PROTECTED_AUTH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
      case PROTECTED_AUTH_FAILURE:
        return {
          ...state,
          isLoading: false,
          errorData: action.payload,
          error: true,
        };
      default:
          // console.log(`checklogin-action`, action.payload)
          // throw new Error();
          return {
            ...state
          };
  }
};